.spicy-datatable {
    width: 98%;
    overflow: scroll;
    padding: 0;
    margin: 0 auto;
    background: #fff;
    font-size: 0.875rem;
    text-align: left;
    border-spacing: 0;
    margin:10px;
    text-align:center
  }
  
  .spicy-datatable > tbody > tr > td,
  .spicy-datatable > tfoot > tr > td,
  .spicy-datatable > thead > tr > th {
    box-sizing: border-box;
    
    padding: 10px 10px;
    border: none;
    border-top: 1px solid #ddd;
    word-break: break-all;
    vertical-align: middle;
    border:1px solid lightgrey;
  }
  
  .spicy-datatable > tbody > tr {
    transition: 0.25s all;
  }
  
  .spicy-datatable > tbody > tr:hover {
    background: whitesmoke;
  }
  
  .spicy-datatable > thead > tr > th {
    border-top: 0;
    border:1px solid lightgrey;
  }
  
  .spicy-datatable > tbody > .spicy-datatable--selected-row {
    background: #7a7ca0;
  }
  
  /*
    === ENTRY COUNTER AT BOTTOM ===
  */
  
  .spicy-datatable-counter {
    text-align: left;
    font-size: 12px;
    padding: 0 20px;
  }
  
  /*
    === PAGINATION SETTINGS ===
  */
  
  .spicy-datatable-pagination-root {
    width: 100%;
    position: relative;
    margin: 1rem auto;
    overflow: auto;
  }
  
  .spicy-datatable-pagination-list {
    list-style: none;
    display: inline-block;
    position: relative;
    background: #fff;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .spicy-datatable-pagination-list .spicy-datatable-pagination-button {
    font-size: 12px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    float: left;
    color: #ccc;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 100%;
    transition: 0.25s all;
  }
  
  .spicy-datatable-pagination-list .spicy-datatable-pagination-button-elipsis:hover {
    background: #fff !important;
  }
  
  .spicy-datatable-pagination-list .spicy-datatable-pagination-button.active {
    background: #7a6cb0;
    color: #fff;
  }
  
  .spicy-datatable-pagination-list .spicy-datatable-pagination-button:hover {
    background: #7a7ca0;
    color: #fff;
  }
  
  .spicy-datatable-pagination-list .spicy-datatable-pagination-button-elipsis:hover {
    background: #fff !important;
    color: #ccc;
    cursor: default;
  }
  
  /*
    === TOP OPTIONS ===
  */
  
  .spicy-datatableoptions-search {
    text-align: right;
    margin:5px 15px;
    
    float: right;
  }
  
  .spicy-datatableoptions-sizepicker {
    float: left;
    margin:5px 15px;
    text-align: left;
    cursor: pointer;
  
  
  }
  
  .spicy-datatableoptions-sizepicker--selectfield {
    margin: 0 5px;
    padding:5px;
  background-color: #e67e22;
    color:#fff;
    padding:7px 10px;
    border:none;
    border-radius: 5px; 
    outline:none;  
  }
  
  .spicy-datatableoptions-search--input {
    margin-left: 10px;
  border-radius:5px;
  }
  
  .spicy-datatableoptions-search--input,
  .spicy-datatableoptions-search--label {
    cursor: pointer;
  }
  
  .spicy-datatableoptions-export--button-wrapper {
    float: right;
    margin:5px;
    
    clear: both;
  }

  .spicy-datatableoptions-export--button {
    cursor: pointer;
  background-color: #e67e22;
    color:#fff;
    padding:7px 10px;
    border:none;
    border-radius: 5px;
  }