//popup on landing page
html{
    scroll-behavior: smooth;
  }
  h4,h3,h2,h1,p{
    margin:0;
}
  .popup-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    z-index: 1000;
  }
  
  .microsite-modal{
      position: fixed;
      height: 74vh;
      background: #fff;
      width: 100vh;
      left: 50%;
      top: 45%;
      transform: translate(-50%,-50%);
      z-index: 1000000000000;
      position: relative;

      .pop-up-carousel {
          ol{
          display: none;
        }
          img{
              height: 74vh;
          }
      }

        .register-button{
            z-index: 10000;
            margin-bottom: 1rem;        
            background: #5165ff !important;
            border-radius: 3px !important;
            font-weight: 700;
            padding: .5rem 1rem;
        }
  
      .close-icon{
          text-align: right;
          position: absolute;
          right: 0;
          i{
              font-size: 30px;
              color: #fff;
              opacity: 1;
              cursor: pointer;
          }
      }
      
  }
  
  @media all and (max-width: 814px) {
    .microsite-modal{
      width: 95%;
    }
  }
  
.btn-wrapper{
    width: 100%;
    height: 74vh;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    a{
        color: white;
    }
}
  .btn-default {
    top: 25%;
    left:25%; 
    color: #999; 
    background: #fffccc; 
} 