.contact{


    .content-one{

      .heading-one{
          font-family: "Open Sans"; 
          font-weight: bolder;
          margin-top:15px;
          font-size: 30px;
          color:#5165FF;
          @media screen and(max-width:576px) {
              margin: 15px 0px;
          }
      }

      p{
          color:#929292;
          font-size: 16px;
          @media screen and(max-width:576px) {
              margin: 15px 0px;
          }
      }
      .button{
          text-decoration: none;
          background-color: #5165FF;
          color:#fff;
          border:none;
          padding:7px 25px;
          border-radius: 5px;
          font-size:13px;
          font-weight: 'semi-bold';
          box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        }
    }
    
    padding:30px 75px;
    @media (max-width:576px) {
        padding:30px 20px;
    }
    @media (min-width:576px)and (max-width:992px) {
        padding:30px 20px;
    }
    .header{
        display:flex;
        justify-content: space-between;
      
        div{
            span{
            font-family: Helvetica;
            font-size: 18px;
            font-weight: bold;
            @media (max-width:576px) {
               display: none;
                }
            }
            hr{
                background-color: #5165ff;
                margin-top:2px;
                height: 4px;
                border: 0px;
            }
        }
        img{
            width:20px;
            height:20px;
            cursor: pointer;
        }
    }
    .content{
         margin-top:35px;
         display:flex;
         @media (max-width:576px) {
           flex-direction: column;
           margin-top:0px;
             }
         .contact1{
             flex:1.5;
          
            .heading{
                font-size:25px;
                color:#5165FF;
                font-weight:bold;
            }
            .mobile-map{
              display: none;
                @media (max-width:576px) {
                    width: 100vw;
                    height: 200px;
                    margin-left: -20px !important;
                   display: inline-block;
                   overflow: hidden;
                      }
            }
            p{
                font-size:15px;
                color:#6A6A6A;
                img{
                    width: 16px;
                    height: 16px;
                }
            }   
            }
         .input{
            flex:1;
          
            .heading{
                height:15%;
                font-size: 18px;
                font-weight: 600;
                color: #000000;
                
            }
            .inputBox{
                border: 1px solid #ebebeb;
                margin-top: 20px;
                height: 200px;
                width:100%;
                padding: 10px 0px 0px 20px;
                color:#929292;
                border-radius: 4px;
                
            }
            .button{
                    text-decoration: none;
                    background-color: #5165FF;
                    color:#fff;
                    border:none;
                    padding:7px 25px;
                    border-radius: 5px;
                    font-size:13px;
                    font-weight: 'semi-bold';
                    margin-top: 20px;
                    box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
                }
            }
    }
    .desktop-map{

                margin-top: 70px;        
              width: 63vw;
              @media all and(max-width: 576px){
                  display: none;
              }
              @media screen and(min-width:576px) and(max-width:768px) {
                width: 73vw !important;
              }
              @media screen and(min-width:768px) and(max-width:996px) {
                width: 71vw !important;
              }
              height: 300px;
              margin-left: -97px !important;
             display: inline-block;
             overflow: hidden;
                
      }



       .renderField{
    label{
        font-size: 12px;
        color:#B0B0B0;
         margin-top:5px;
         
    }
    .input{
      outline:none;
      border: 1px solid #ebebeb;
        border-radius:5px; 
        min-width:25vw;
        margin-top:5px;
        min-height:25px;
        padding:10px;
        font-size:12px;
        @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
    .textArea{
        outline:none;
        border: 1px solid #ebebeb;
          border-radius:5px;
          height: 200px; 
          min-width:25vw;
          margin-top:5px;
          padding:10px;
          font-size:12px;
          @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
    span{
        color:red;
        font-size:12px;
        margin-bottom: 5px;
    }
    
}

}