.form{
    background-color:#F8F9FF;
    padding:10px;
    margin-left: -30px;
    margin-right: -20px;

    .company-list-error-message{
        color: red;
        font-size: 12px;
    }
  
    .form-1{
        margin:20px 65px;
        @media screen and(max-width:576px) {
            margin: 20px 20px;
        }
         .form-header{
                display: flex;
                font-size: 16px;
                .number{
                  text-align: center;
                  vertical-align: middle;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                    margin:5px;
                     height: 30px;
                    width: 30px;
                    background-color:#5165FF;
                    color: #fff;
                    border-radius: 50%;
                    @media screen and(max-width:576px) {
                        margin: 0px 5px 5px 0px;
                    }
                    
                }
                .subHeading{
                    flex:3;
                    margin-left: 2vw;
                    display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
            }
            form{
                margin-left:5vw;
                @media all and(max-width:576px) {
                    margin-left: 0vw;
                    
                }
                @media all and(min-width:577px) and(max-width:767px) {
                    margin-left: 7vw;
                    
                }
                @media all and(min-width:768px) and(max-width:996px) {
                    margin-left: 6vw;
                    
                }
            }

        }

}


 .inActiveHeader{
                border-radius: 4px;
                box-shadow: 0 4px 15px 3px rgba(223, 223, 223, 0.5);
                background-color: #ffffff;
                margin: 15px 35px;
                display: flex;
                padding: 10px;
                width: 65%;                
                font-size: 16px;
                @media screen and(max-width: 576px){
                    margin: 15px 0px;  
                    width: 100%;                  
                }
                img{
                    width: 26px;
                    padding-right: 10px;
                }
                .number{
                  text-align: center;
                  vertical-align: middle;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                    margin:5px;
                     height: 30px;
                    width: 30px;
                    background-color:#f8f9ff;;
                    color: #5165ff;
                    border-radius: 50%;

                }
                .subHeading{
                    flex:3;
                    margin:5px 5px 5px 20px;
                    display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
            }




.renderField{
    margin-bottom: 12px;
    label{
        font-size: 12px;
        color:#B0B0B0;
         margin-top:5px;
         
    }
    .input{
      outline:none;
      border:1px solid #ebebeb;
        border-radius:5px; 
        min-width:25vw;
        margin-top:5px;
        min-height:25px;
        padding:10px;
        font-size:12px;
        @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
    .textArea{
        outline:none;
        border:1px solid #ebebeb;
          border-radius:5px;
          height: 200px; 
          min-width:25vw;
          margin-top:5px;
          padding:10px;
          font-size:12px;
          @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
    span{
        color:red;
        font-size:12px;
        margin-bottom: 5px;
    }
    
}


.renderSelect{
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    .ant-select-selection--single {
        width: 25vw;
        @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
    .ant-select-selection--multiple {
        width: 25vw;
        @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
   label{
        font-size: 12px;
        color:#B0B0B0;
         margin-top:5px;
         font-weight: normal;
    }
    select{
      outline:none;
      border:1px solid #ebebeb;
        border-radius:5px; 
        min-width:25vw;
        margin-top:5px;
        height:20px;
        padding:10px;
        font-size:12px;
        @media screen and(max-width: 576px){
            width: 80vw;                  
        }
    }
    span{
        color:red;
        font-size:12px
    }



}

.nextsignup{
        margin-top:30px;
        padding:5px 25px;
        border:none;
        border-radius: .2em;
        color:#fff;
        font-size:14px;
        background-color: #5165FF;
        cursor: pointer;
    }
    .previous{
        margin-top:30px;
        padding:5px 25px;
        border:none;
        border-radius: .2em;
        color:#fff;
        font-size:14px;
        background-color: #5165FF;
    }

.ant-select-dropdown{
    z-index: 9999999 !important;
    width: 25% !important;
    @media screen and(max-width: 576px){
        width:90% !important;
    }
}