.editRecord{
    overflow: auto;
    max-height:80vh;
    .row{
        display: flex;
        padding:10px;
       
        .col1{
            flex: 1;
            display:flex;
            .label{
                flex:1
            }
            .input{
                flex:1
            }
        }
        .col2{
            flex: 1;
            display:flex;
            .label{
                flex:1
            }
            .input{
                flex:1
            }
        }
    }
     .buttonSave{
            width:120px;
            padding:5px 10px;
            margin-right: 10px;
            background-color:  #2c2c54;
            color:#fff;
            text-decoration: none;
            outline: none;
            border: none;
            border-radius: 4px;
            &:hover{
                background-color: #40407a;
                color:#fff

            }
        }
     .buttonBack{
            width:120px;
            padding:5px 10px;
            background-color: #227093;
            color:#fff;
            text-decoration: none;
            outline: none;
            border: none;
            border-radius: 4px;
            &:hover{
                background-color: #34ace0;
                color:#fff

            }
            
        }
}