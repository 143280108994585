.footer {
  position: absolute;
  height: 80px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
 border-top: 1px solid #ebebeb;
  @media screen and(max-width:750px) {
    flex-direction: column;
    text-align: center;
  }
  .copyright {
    display: flex;
    flex-direction: row;
    @media screen and(max-width: 750px) {
      flex-direction: column;
    }
  }
  @media (max-width: 576px) {
    .copyright > :nth-child(1) {
      order: 2;
    }
    .copyright > :nth-child(2) {
      order: 1;
    }
  }
  img {
    height: 5vh;
    margin: 20px 3px;
    @media (max-width: 576px) {
      height: 5vh;
    margin: 20px 3px 20px 3px;
    
    }

  }
  span {
    padding: 20px 0px 20px 0px;
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 1.8;
    color: #929292;
  }
  .social-icons {
    img {
      width: 24px;
      height: 24px;
    }
    @media (max-width: 576px) {
      text-align: center;
    }
  }
}
