.App {
  .detail {
    display: flex;
    margin-left: 0px !important;
    margin-right: 0px !important;
    overflow-x:hidden; 
    @media screen and(max-width:768px) {
      flex-direction: column;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media (max-width: 576px) {
    height: 40vh;
    width: 100%;
    .detail > :nth-child(1) {
      order: 2 !important;
    }
    .detail > :nth-child(2) {
      order: 1 !important;
    }
    .detail > :nth-child(3) {
      order: 3 !important;
    }
  }
  .itemDetail-Map {
    min-width: 25vw;
    @media (max-width: 576px) {
      height: 40vh;
      width: 100%;
    }
    .map-height {
      height: 100%;
      width: 100%;
      @media (max-width: 576px) {
        height: 100vh;
        width: 100%;
      }
      @media screen and(min-width:750px) {
        height: 100vh;
        width: 100%;
      }
      @media screen and(min-width:751px) {
        height: 100%;
        width: 100%;
      }
    }
  }

  .item-detail {
    min-height:80vh;
    min-width: 50vw;
    .back-arrow {
      padding-left: 20px;
      @media (max-width: 576px) {
        padding-left: 15px;
      }
      display: flex;
      cursor: pointer;
      img {
        float: right;
        margin: 12px 0px 0px 10px;        
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
      span{
        position: relative;
        top: 10px;
        left: 10px;
      }
    }
    .content-section {
      padding-left: 20px;
        @media all and(max-width:576px) {
          padding-left: 12px;
          
      }
      .item-info {
        padding: 10px 10px 10px 10px;
        h3 {
          font-family: "Open Sans";
          font-size: 30px;
        
          line-height: 28px !important;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.67;
          color: #0a0a0a;
        }
        .back-link {
          width: 71px;
          height: 19px;
          cursor: pointer;
          font-family: "Open Sans";
          font-size: 14px;
          color: #a4afd0;
        }
        
        .item-reviews {
          display: flex;
          flex-direction: row;
          padding: 0px 5px 0px 0px;
          font-size: 14px;
          height: 30px;
          color: #929292;
        

          .seperator {
            border-right: 1px solid #929292;
            height: 15px;
            margin-top: 3px;
            margin-right: 20px;
            margin-left: 20px;
          }
          img {
            height: 18px;
            margin-right: 20px;
          }
        }
        .item-category {
          display: flex;
        }
        .tags {
          
          margin-top: 15px;
          
          span {
            margin-right: 10px;
            padding: 5px 10px 5px 10px;
            text-align: center;

            border-radius: 140px;
            background-color: #f8f9ff;
            @media screen and(min-width:750px) and(max-width:996px) {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 90px !important;
              min-width: 30px !important;
              display: inline-block;
            }
            @media all and(max-width: 576px){
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 45% !important;
              display: inline-block;
            }
           
          }
        }
        .item-description {
          margin-top: 10px;
          min-height:10vh;
          overflow:auto;
          p {
            font-family: "Open Sans";
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: normal;
            color: #929292;
          }
        }
        .social-icons {
          .left {
            float: left;
            img {
              padding: 0px 10px 0px 0px;
            }
          }
          .right {
            float: right;
            img {
              padding: 0px 10px 0px 0px;
            }
          }
        }
      }
      .hr {
        width: 100%;
        border: 1px solid #ebebeb;
      }
      .review-section {
        margin-bottom: 20px;
            overflow:auto;
        max-height: 35vh;
        @media all and(max-width:576px) {
          padding: 10px 10px 40px 10px;
      }
        h3 {
          margin: 30px 0px 20px 0px !important;
          font-size: 18px;
        }
        span {
          width: 48px;
          height: 19px;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          color: #0a0a0a;
        }
        .user {
          display: flex;
          height: 14px;
        }
        .user-comments {
          margin-top: 20px;
          font-family: "Open Sans";
          font-size: 14px;
          letter-spacing: normal;
          color: #929292;
        }

        .Leave-review {
          a {
            font-family: "Open Sans";
            font-size: 14px;
            color: #5165ff;
          }
        }
        .inputBox{
          border: 1px solid #ebebeb;
          height: 200px;
          width:100%;
          padding: 10px 0px 0px 20px;
          color:#929292;
          border-radius: 4px;            
        }
        .button{
          text-decoration: none;
          background-color: #5165FF;
          color:#fff;
          border:none;
          padding:7px 25px;
          border-radius: 5px;
          font-size:13px;
          font-weight: 'semi-bold';
          box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        }
      }
    }
  }

  .itemDetail-contact {
    min-width: 25vw;
    max-width: 25vw;
    overflow-x: hidden;
    @media screen and(min-width:576px) and(max-width:768px) {
     min-width: 100% !important;
    }

    @media screen and(max-width:750px) {
      min-width: 100vw;
    }
    @media screen and(min-width:751px) {
      min-width: 25vw;
    }
    padding: 20px;
    h4 {
      font-size: 18px;
    }
    .contact-section {
      padding-bottom: 20px;
      .item-contact {
        flex: 0.5;
        display: flex;
        flex-direction: row;
        padding: 1px;
        img {
          max-width: 10px;
          max-height: 15px;
        }
        span {
          padding-left: 10px;
          font-size: 14px;
        }
      }
    }
    .open-hour {
      padding-bottom: 20px;
      .schedule {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        line-height: 2;
        .day {
          min-width: 7vw;
          @media all and(max-width:576px) {
            min-width:20vw;
          }
        }
        .time {
          padding: 0px;
        }
        @media screen and(min-width:769px) and(max-width:876px) {
          flex-direction: column;
        }
      }
    }
    .image-section {
      .img {
        min-width: 25vw;
        max-width: 25vw;
        min-height: 30vh;
        max-height: 30vh;
        overflow-x: hidden;

        @media screen and(max-width:750px) {
          min-width: 100vw;
        }
        @media screen and(min-width:751px) {
          min-width: 25vw;
        }
      }
      .image-row {
        cursor: pointer;
        max-width: 25vw;
        display: flex;
        flex-wrap: wrap;
        

        @media screen and(max-width:750px) {
          min-width: 25vw;
        }
        @media screen and(min-width:751px) {
          max-width: 25vw;
        }

        .image {
          max-height: 9vh;
          width: 22%;
          margin:2px;
          float: left;
          @media screen and(max-width:750px) {
            min-width: 25vw;
          }
          @media screen and(min-width:751px) {
            max-width: 25vw;
          }
        }
      }
    }
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
