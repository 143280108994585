.registerWrapper{
  background: rgb(81, 101, 255);
.register {
  padding:30px 75px;
  @media (max-width:576px) {
      padding:30px 20px;
  }
  @media (min-width:576px)and (max-width:992px) {
      padding:30px 20px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    div {
      span {
        color: #f8f9ff;
        font-family: Helvetica;
        font-size: 18px;
      }
      hr {
        background-color: #a4afd0;
        margin-top: 2px;
        height: 4px;
        border: 0px;
      }
    }
    img {
      position: relative;
      top: -30px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .content {
    margin-top: 35px;
    .heading {
      font-family: "Open Sans";
      font-weight: bolder;
      margin: 15px 0px;
      font-size: 30px;
      color: #fff;
    }
    .subHead {
      span {
        font-size: 14px;
        margin: 5px;
        font-weight: bold;
      }
    }
  }
  .register-success-message {
    color:green;
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .register-login-error-message {
    color:  red; 
    margin-top: 10px;
  }
}
}