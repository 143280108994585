@charset "UTF-8";
.App {
  min-height: 100vh; }
  .App .menuItemMobileNone {
    display: none; }
  @media (max-width: 768px) {
    .App .menuItemMobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 2; }
      .App .menuItemMobile .headerMobileMenu {
        display: flex;
        justify-content: space-between;
        margin: 10vw;
        font-size: 16px; }
        .App .menuItemMobile .headerMobileMenu img {
          cursor: pointer; }
      .App .menuItemMobile .headerMobileMenu {
        font-size: 16px;
        color: #4c4c4c;
        margin: 10vw; }
        .App .menuItemMobile .headerMobileMenu .itemMobile {
          padding: 10px; }
      .App .menuItemMobile .contentMobileMenu .upper-section {
        margin-left: 30px;
        margin-bottom: 30px; }
        .App .menuItemMobile .contentMobileMenu .upper-section .item {
          padding: 10px;
          font-size: 18px;
          font-weight: 600;
          font-weight: "semi-bold"; }
          .App .menuItemMobile .contentMobileMenu .upper-section .item a {
            color: #4c4c4c; }
        .App .menuItemMobile .contentMobileMenu .upper-section .getStarted {
          text-align: center;
          margin-left: 10px;
          margin-top: 20px;
          width: 200px;
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
          font-weight: 600;
          padding: 9px 15px;
          border-radius: 4px;
          box-shadow: 0 6px 8px 0 rgba(211, 207, 207, 0.5);
          background-color: #5165ff; }
          .App .menuItemMobile .contentMobileMenu .upper-section .getStarted a {
            color: #ffffff; }
      .App .menuItemMobile .lower-section {
        background-color: #fbfcff;
        height: 100%; }
        .App .menuItemMobile .lower-section .registerDiv {
          padding: 20px 10px 10px 10px;
          margin-left: 30px;
          font-family: "Open Sans";
          font-size: 18px;
          font-weight: 600;
          color: #4c4c4c; } }
  @media (min-width: 768px) {
    .App .menuItemMobile {
      display: none; } }
  .App .menuMobile {
    display: none; }
    @media (max-width: 768px) {
      .App .menuMobile {
        display: flex;
        justify-content: space-between;
        max-height: 60px; }
        .App .menuMobile .logoDiv .logo {
          width: 150px;
          padding: 20px 20px; } }
    @media (max-width: 768px) and (max-width: 768px) {
      .App .menuMobile .logoDiv .logo {
        padding: 10px 20px; } }
    @media (max-width: 768px) {
        .App .menuMobile .itemDiv {
          display: flex; }
          .App .menuMobile .itemDiv ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            .App .menuMobile .itemDiv ul li {
              cursor: pointer;
              padding: 6px 20px;
              font-size: 18px; }
          .App .menuMobile .itemDiv button {
            opacity: 0.6;
            background-color: transparent;
            position: absolute;
            z-index: 13;
            top: 24px;
            right: 20px;
            border: none;
            width: 30px;
            height: 24px;
            outline: none;
            transition: opacity 0.2s ease-out; } }
      @media (max-width: 768px) and (max-width: 768px) {
        .App .menuMobile .itemDiv button {
          top: 20px; } }
    @media (max-width: 768px) {
            .App .menuMobile .itemDiv button:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              right: auto;
              width: 100%;
              background: linear-gradient(to bottom, #000000, #000000 20%, transparent 20%, transparent 40%, #000000 40%, #000000 60%, transparent 60%, transparent 80%, #000000 80%, #000000 100%);
              transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out; }
            .App .menuMobile .itemDiv button:after {
              opacity: 0;
              content: '×';
              color: #000000;
              position: absolute;
              top: 16px;
              left: -4px;
              font-family: Arial, sans-serif;
              font-size: 50px;
              line-height: 0;
              transition: opacity 0.4s ease-out; }
            .App .menuMobile .itemDiv button:active {
              transform: translateY(2px); }
            .App .menuMobile .itemDiv button:hover {
              opacity: 1; }
            .open .App .menuMobile .itemDiv button {
              opacity: 1; }
              .open .App .menuMobile .itemDiv button:before {
                opacity: 0;
                width: 0; }
              .open .App .menuMobile .itemDiv button:after {
                opacity: 1;
                transform: translate3d(0, 0, 0) rotate(360deg);
                transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out; }
          .App .menuMobile .itemDiv nav {
            z-index: 12;
            position: fixed;
            top: -100%;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            overflow: hidden; }
            .App .menuMobile .itemDiv nav:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              background: #ffffff;
              width: 100%;
              height: 0;
              padding-bottom: 100%;
              border-radius: 100%;
              transform: scale(0.04), translateY(9999px);
              overflow: hidden; }
            .open .App .menuMobile .itemDiv nav {
              top: 0; }
              .open .App .menuMobile .itemDiv nav:before {
                animation: menu-animation 0.8s ease-out forwards; }
          .App .menuMobile .itemDiv ul.menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 60%;
            left: 55%;
            transform: translate3d(-50%, -50%, 0);
            backface-visibility: hidden;
            perspective: 1000;
            color: #000000; }
            .App .menuMobile .itemDiv ul.menu .registerLi {
              margin: 30px -20px 0px -40px;
              height: 50vh; } }
        @media (max-width: 768px) and (max-width: 768px) {
          .App .menuMobile .itemDiv ul.menu .registerLi {
            margin: 30px -20px 0px -60px; } }
    @media (max-width: 768px) {
            .App .menuMobile .itemDiv ul.menu li {
              opacity: 0;
              transform: translate3d(0, 36px, 0); }
              .App .menuMobile .itemDiv ul.menu li span {
                background-color: #5165ff;
                padding: 10px;
                color: white;
                border-radius: 4px;
                box-shadow: 0 6px 8px 0 rgba(211, 207, 207, 0.5); }
              .App .menuMobile .itemDiv ul.menu li:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                left: auto;
                background-color: #5165ff;
                width: 0;
                overflow: hidden;
                transition: width 0.14s ease-out; }
              .App .menuMobile .itemDiv ul.menu li:after {
                opacity: 0;
                content: attr(data-text);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                color: #fff;
                overflow: hidden;
                transform: translate(-24px, 6px);
                transition: transform 0.1s ease-out, opacity 0.1s ease-out; }
              .open .App .menuMobile .itemDiv ul.menu li {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                transition: transform 0.2s ease-out, opacity 0.2s ease-out; }
                .open .App .menuMobile .itemDiv ul.menu li:nth-child(1) {
                  transition-delay: 0.75s; }
                .open .App .menuMobile .itemDiv ul.menu li:nth-child(2) {
                  transition-delay: 0.85s; }
                .open .App .menuMobile .itemDiv ul.menu li:nth-child(3) {
                  transition-delay: 0.95s; }
                .open .App .menuMobile .itemDiv ul.menu li:nth-child(4) {
                  transition-delay: 1.05s; }
              .App .menuMobile .itemDiv ul.menu li .lower-section {
                background-color: #fbfcff;
                height: 100%; }
                .App .menuMobile .itemDiv ul.menu li .lower-section .registerDiv {
                  padding: 20px 10px 10px 10px;
                  margin-left: 30px;
                  font-family: "Open Sans";
                  font-size: 18px;
                  font-weight: 600;
                  color: #4c4c4c; } }
            @media (max-width: 768px) and (max-width: 768px) {
              .App .menuMobile .itemDiv ul.menu li .lower-section .registerDiv {
                margin-left: 48px; } }
    @media (max-width: 768px) {
      @keyframes menu-animation {
        0% {
          opacity: 0;
          transform: scale(0.04) translateY(300%); }
        40% {
          transform: scale(0.04) translateY(0);
          transition: ease-out; }
        40% {
          transform: scale(0.04) translateY(0); }
        60% {
          opacity: 1;
          transform: scale(0.02) translateY(0px); }
        61% {
          transform: scale(0.04); }
        99.9% {
          height: 0;
          padding-bottom: 100%;
          border-radius: 100%; }
        100% {
          transform: scale(2);
          height: 100%;
          padding-bottom: 0;
          border-radius: 0; } }
          .App .menuMobile .itemDiv .logo {
            position: relative;
            width: 150px;
            padding: 10px 20px; } }
  .App .menu {
    margin-right: 50px;
    display: flex; }
    @media screen and (max-width: 768px) {
      .App .menu {
        display: none; } }
    .App .menu .logoDiv {
      flex: 0.9;
      margin-right: 100px; }
      .App .menu .logoDiv .logo {
        width: 160px;
        padding: 10px 20px; }
      @media screen and (min-width: 770px) and (max-width: 1090px) {
        .App .menu .logoDiv {
          margin-right: 10px; } }
    .App .menu .itemDiv {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .App .menu .itemDiv .item {
        display: flex; }
        .App .menu .itemDiv .item .normal {
          margin: 0px 10px;
          font-size: 14px;
          padding: 7px; }
          @media screen and (min-width: 767px) and (max-width: 870px) {
            .App .menu .itemDiv .item .normal {
              padding: 22px 0px 0px 0px;
              white-space: nowrap; } }
          @media screen and (min-width: 870px) and (max-width: 935px) {
            .App .menu .itemDiv .item .normal {
              padding: 20px 0px 0px 0px;
              white-space: nowrap; } }
          @media screen and (min-width: 935px) and (max-width: 976px) {
            .App .menu .itemDiv .item .normal {
              padding: 8px 0px 0px 0px;
              white-space: nowrap; } }
        .App .menu .itemDiv .item .getStarted {
          margin: 0px 10px;
          font-size: 14px;
          border-radius: 3px;
          color: #fff;
          font-weight: 700;
          padding: 7px 15px;
          background-color: #5165ff; }
          .App .menu .itemDiv .item .getStarted a {
            color: white; }
          @media screen and (min-width: 767px) and (max-width: 870px) {
            .App .menu .itemDiv .item .getStarted {
              white-space: nowrap;
              margin-top: 10px; } }
        .App .menu .itemDiv .item a {
          color: #4c4c4c; }
    .App .menu .profileDiv {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 14px; }
      .App .menu .profileDiv .dropbtn {
        margin-top: 7px; }
      .App .menu .profileDiv .dropdown {
        position: relative;
        display: inline-block; }
      .App .menu .profileDiv .dropdown-content {
        margin-top: 10px;
        display: none;
        right: 0px;
        position: absolute;
        background-color: white;
        min-width: 160px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 3; }
      .App .menu .profileDiv .dropdown-content:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 80%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-bottom-color: white;
        border-top: 0;
        border-left: 10;
        margin-left: -10px;
        margin-bottom: -10px; }
      .App .menu .profileDiv .dropdown-content a {
        color: #4c4c4c;
        padding: 12px 16px;
        text-decoration: none;
        display: block; }
      .App .menu .profileDiv .dropdown-content a:hover {
        background-color: #ddd; }
      .App .menu .profileDiv .dropdown:hover .dropdown-content {
        display: block; }
    .App .menu .registerDiv {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .App .menu .registerDiv a {
        color: #4c4c4c; }
      .App .menu .registerDiv .user-name {
        color: #4c4c4c; }
  .App .fix-header {
    position: fixed;
    z-index: 9999;
    background: #fff;
    width: 100%;
    margin-top: 0px;
    top: 0px; }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); }

.body-top {
  padding-top: 60px; }

.Overlay {
  z-index: 55555; }
