.publications{
	.iframe {
		width: 100%;
		height: 500px;
		@media all and(max-width:576px) {
    	height: 400px;
  	}
	}
}

h3 {

	font-size:16px;
}
