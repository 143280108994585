*{
  font-family: 'Open Sans';
}
.App {
  .home {
    display: flex;
    margin-left: 0px !important;
    margin-right: 0px !important;
    overflow-x: hidden;
    @media screen and(max-width:750px) {
      flex-direction: column;
      margin-left: 0px;
      margin-right: 0px;
    }
    

    .map-section {  
      position: relative;
      .toggler {
        position: absolute;
        top: 10px;
        z-index: 11;
        right: 40px;
        @media all and(max-width:768px) {
          right: 20px;
          position: unset;
          box-shadow: none;
          text-align: center;
          margin-bottom: 10px
        }
        color: white;
        border-radius: 4px 0px 0px 4px;
       box-shadow:0 2px 10px 1px #929292;
       
  
        .map-toggle-btn {
          cursor: pointer;
          min-width: 60px;
          border: none;
          padding: 5px;
          background: #ffffff;
          border-radius: 4px 0px 0px 4px;
          color: #000000;
          font-family: 'Open Sans';
          &.active {
            background: #5165ff;
            border: none;
            padding: 5px;
            border-radius:4px 0px 0px 4px;
            color: #ffffff;
            font-weight: 600;
          }
          float: left;
          @media all and(max-width:768px) {
            float: none;
          }
        }
        .list-toggle-btn {
          cursor: pointer;
          min-width: 60px;
          border: none;
          padding: 5px;
          background: #ffffff;
          border-radius: 0px 4px 4px 0px;
          color: #000000;
          font-family: 'Open Sans';
          &.active {
            background: #5165ff;
            border: none;
            padding: 5px;
            border-radius:0px 4px 4px 0px;
            color: #ffffff;
            font-weight: 600;
          }
          float: left;
          @media all and(max-width:768px) {
            float: none;
          }
        }
      }
      .divMap {
        display: flex;
        width: 41vw;
        height: 100%;
          @media screen and (max-width:750px) {
              width: 100vw;
              height:500px;

          }
        
        .map {
          height: 100%;
          width: 100%;
            @media screen and (max-width:750px) {
              width: 100vw;
              height:500px;

          }
          .mapboxgl-popup-content {
           
            padding: 0px !important;
            border-radius: 10px;
            box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
            @media all and(max-width:576px){
              width: 70%;
            }
          }
          .popup {
            width:auto ;
            height: auto;
            padding: 0px !important;
            .popup-content {
              padding: 10px 10px 10px 30px;
              width: 25vw;
              border-radius: 10px;
              box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
              background-color: #ffffff;
              border-bottom: 1px solid #ebebeb;
              @media screen and(max-width:750px) {
                width: 100%;
              }
              @media all and(max-width:568px){
                padding: 10px 10px 10px 10px;
              }
              .popup-header {
                display:flex;
                justify-content: space-between;
                .img{
                  width:22px;
                  height:22px;
                  padding:3px;
                  cursor: pointer;
                }
                .title {
                  font-family: "Open Sans";
                  font-size: 12px;
                  font-weight:700;
                  color: #000000;
                  width: 100%;
                  @media all and(max-width:568px){
                    width: 100%;
                    font-size: 10px;
                    line-height: 1.4;
                  }
                }
              }
              .category {
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 400;
                color: #929292;
                padding-top:10px;
                @media all and(max-width:568px){
                  font-size: 10px;
                }
              }
              .labels {
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                span {
                  margin-right: 5px;
                  padding: 0px 10px 2px 10px;
                  text-align: center;
                  border-radius: 140px;
                  background-color: #f8f9ff;
                  font-size: 11px;
                  color: #a4afd0;
                  font-family: "Open Sans";
                  text-transform: lowercase;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  width: 90px !important;
                  min-width: 30px !important;
                  display: inline-block;
                }
              }
              .contact-section {
                margin: 10px 0px 10px 0px;

                .item-contact {
                  display: flex;
                  flex-direction: row;
                  padding: 1px;
                  color: #4c4c4c;
                  img {
                    max-width: 10px;
                    max-height: 15px;
                  }
                  span {
                    padding-left: 10px;
                    font-size: 13px;
                    @media all and(max-width:568px){
                      font-size: 10px;
                      line-height: 1.4;
                    }
                  }
                }
              }
              .reviews-section {
                width: 100%;
                display: flex;
                .rating {
                  height: 16px;
                  @media all and(max-width:568px) {
                  height: 10px;
                  }
                }
                .reviews {
                  height: 16px;
                  margin-left: 15px;
                  color:#929292;
                  @media all and(max-width:568px) {
                    font-size: 10px;
                    position: relative;
                    top: -5px;
                  }
                }
              }
            }
          }
        }
      }
      .hide {
        display: none !important;
      }
      .list-section {
        margin-top: 15px;
        width: 41vw;
       max-height: 92vh;
        overflow: auto;
        padding: 0px 20px 0px 20px;
        color: #5165ff;
      @media screen and(max-width:750px) {
            width: 100vw;
          }
        .category {
          font-size: 14px;
          font-weight: 600;
          font-style: "Open Sans";
          text-transform: capitalize;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100% !important;
          span {
            color: #000000;
            
          }
        }
        .category-sticky{
          position: absolute;
          background: #fff;
          padding-bottom: 12px;
        }
        .first-item {
          margin-top: 53px !important;
        }
        .list-item {
          margin-top: 30px;
          width: 100%;
          cursor: pointer;
          border-bottom: 1px solid #ebebeb;
          @media screen and(max-width:750px) {
            width: 100%;
          }
          .item-header {
            .title {
              font-family: "Open Sans";
              font-size: 18px;
              font-weight: 600;
              color: #000000;
              width: 70%;
            }
            .rating {
              display:flex;
              flex-direction: column;
              float: right;
              img{
              height: 18px;
              margin-bottom: 5px;
                }
            }
            .reviews {
              height: 18px;
              text-align: right;
              color: #929292;
            }
          }
          .category {
            font-family: "Open Sans";
            font-size: 12px;
            color: #929292;
            text-transform:lowercase;
          }
          .labels {
         
            margin-top: 15px;
            span {
              margin-right: 10px;
              padding: 5px 10px 5px 10px;
              text-align: center;
              border-radius: 140px;
              background-color: #f8f9ff;
              font-size: 12px;
              color: #a4afd0;
              font-family: "Open Sans";
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 90px !important;
              min-width: 30px !important;
              display: inline-block;
            }
          }
          .contact-section {
            margin: 10px 0px 10px 0px;

            .item-contact {
              display: flex;
              flex-direction: row;
              padding: 1px;
              color: #4c4c4c;
              img {
                max-width: 10px;
                max-height: 15px;
              }
              span {
                padding-left: 10px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    .fade {
        /*height: 100%;
        width: 100%;
         margin: auto;
            top: 0; left: 0; bottom: 0; right: 0;
        position:absolute;
        z-index: 1;
        background: 
          -webkit-linear-gradient(top, 
          rgba(0,0,0,0.99) 0%, 
          rgba(0,0,0,0) 30%,
          rgba(0,0,0,0) 80%
          );*/
         
          height: 100%;
    width: 100%;
    margin: auto;
    /* top: 50%; */
    left: 0;
    bottom: 0%;
    right: 0;
    position: absolute;
    z-index: 1;
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 80%);



    }

    .divBanner {
      position: relative;
  
      @media screen and(min-width:576px) and (max-width: 750px) {
       
        height: 100vh;
        min-width: 29vh;
        
       
      }
      @media all and(max-width:576px) {
        height: 100vh;
        min-width: 29vw;
        max-height: 18em;
      }
      .slider {
        height: 100% !important;
        min-width: 29vw;
      
        @media (max-width: 576px) {
         height: 27em;
          min-width: 29vw;
        }
        @media screen and(max-width:1251px) {
          min-width: 29vw;
        }
        @media screen and(min-width:576px) and (max-width: 750px) {
          min-height: 100vh;
          min-width: 29vw;
        }
      }

      .previousButton{
        z-index:10;
        svg {
          height: 20px
        }
      }
      .nextButton {
        z-index: 10;
        svg {
          height: 20px
        }
      }

    
      .slider-title {
        h1 {
          color: white;
          text-align: center;
          text-transform:capitalize;
          padding: 10px 10px 0 10px;
          font-weight:700;
          position: absolute;
          top: 46%;
          z-index: 1;
          line-height:29px;
          font-size:30px !important;
          width: 100%;
          @media all and(max-width:768px) {
            font-size:23px !important;
            line-height:20px;
            text-align: center !important;
            top: 44%;
          }

          
          

        }
      }
      .img{
        background-color: #5267ff;
        color:white;
        img{
        height: 18px;
      }
        padding: 5px;
        position: relative;
        left:50%;
        top:-50px;
        display: none;
        padding: 6px;
        border-radius: 30px;
        z-index: 2;
        @media all and(max-width:576px) {
          display:inline;
        }

      }
    }
    .divBanner:hover{
      & a {
        &.previousButton, &.nextButton {
          display: block !important;
        }
        }
    }
    .divMiddleMobileOnly{
      .header{
        display: flex;
        flex-direction: row;
        /*width: 85%;*/
        
      }


 h2 {
        color: #5165ff;
        font-weight: bolder;
        font-size: 18px;
        text-align: center;
        @media all and(min-width:768px) and(max-width:996px) {
          padding: 0px 20px 0px 20px;
         

        }
      }
      
      
      h3 {
        color: #5165ff;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        @media all and(min-width:768px) and(max-width:996px) {
          padding: 0px 20px 0px 20px;
         

        }
      }
      min-width: 30vw;
      padding: 10px;
      background-color: #fbfcff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @media screen and (min-width:750px){
        display:none;
      }

      ul#menu{
              min-width: 80vw;
              height:3em;
              margin:0;
              padding:0px;
              border-radius: 10px;
              box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
              background-color: #ffffff;
              margin-bottom: 10px;
        
            }
      
            ul#menu > li{
              float:left;
              list-style-type:none;
              position:relative;
              cursor: pointer;
            }
      
            label{
      
              position:relative;
              display:block;
              padding:0 18px 0 12px;
              line-height:3em;
              transition:background 0.3s;
              cursor:pointer;
              min-width: 80vw;
      
            }
      
            label:after{
              background-image: url('../../images/arrow-down.svg');
              background-repeat: no-repeat;
              background-size: 20px 35px;
              content: '';
              position: absolute;
              display: block;
              top: 0%;
              right: 0px;
              width: 30px;
              height: 30px;
              transition: all .3s ease-in-out;
              }
      
            label:hover,
            input:checked ~ label{
              border-radius: 10px;
            }
      
            input:checked ~ label:after{
              transform: rotate(-180deg) translateX(10px) translateY(-5px);
            }
      
            /*hide the inputs*/
            input{display:none}
      
            /*show the second levele menu of the selected voice*/
            input:checked ~ ul.submenu{
              max-height:300px;
              transition:max-height 0.2s ease-in;
            }
      
            /*style for the second level menu*/
            ul.submenu{
              max-height:0;
              padding:0;
              overflow:hidden;
              list-style-type:none;
              border-radius: 10px;
              box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
              background-color: #ffffff;
              transition:max-height 0.2s ease-out;
              position:absolute;
              min-width:100%;
              margin:5px 0px 0px 0px;
              z-index: 12;
              .icon{
                  img{
                    width: 20px;
                    height: 20px;
                     }
              }
            }
      
            ul.submenu li a{
              display:block;
              padding: 9px 12px 9px 12px;
              font-family: 'Open Sans';
              font-size: 12px;
              color: #000000;
              text-decoration:none;
              transition:background .3s;
              white-space:nowrap;
            }
      
            ul.submenu li a:hover{
              background:#5165ff;
              color: #ffffff;
            }

    }
    .divMiddle {
      min-width: 30vw;
      padding: 10px;
      background-color: #fbfcff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @media screen and(min-width:751px) and (max-width: 1251px) {
        min-width: 30vw;
      }
      @media screen and(max-width:751px){
        display: none;
      }
      .header{
        display: flex;
        flex-direction: row;
        /*width: 76%;*/
        padding: 1px 0px;
        @media screen and(max-width:680px) {
          width: 90%;
        }
      }

        h2 {
        color: #5165ff;
        font-weight: bolder;
        font-size: 18px;
        text-align: center;
        @media all and(min-width:768px) and(max-width:996px) {
          padding: 0px 20px 0px 20px;
          
        }
      }
      h3 {
        color: #5165ff;
       
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        
        @media all and(min-width:768px) and(max-width:996px) {
          padding: 0px 20px 0px 20px;
          
        }
      }

      /*Style for the first level menu bar*/
      ul#menu{
        min-width: 22vw;
        height:3em;
        margin:0;
        padding:0px;
        border-radius: 5px;
        box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        background-color: #ffffff;
        margin-bottom: 10px;
        font-weight:200;
        font-size:12px;
        @media (max-width:750px) {
          min-width:80vw;
        }
        
      }

      ul#menu > li{
        float:left;
        list-style-type:none;
        position:relative;
      }

      label{

        position:relative;
        display:block;
        padding:0 18px 0 12px;
        line-height:3em;
        transition:background 0.3s;
        cursor:pointer;
        min-width: 22vw;
        @media (max-width:750px) {
          min-width:80vw;
        }

      }

      label:after{
        background-image: url('../../images/arrow-down.svg');
        background-repeat: no-repeat;
        background-size: 20px 35px;
        content: '';
        position: absolute;
        display: block;
        top: 0%;
        right: 0px;
        width: 30px;
        height: 30px;
        transition: all .3s ease-in-out;
        }

      label:hover,
      input:checked ~ label{
        border-radius: 10px;
      }

      input:checked ~ label:after{
       // border-top:0 solid #000000;
        //border-bottom:4px solid #000000;
        transform: rotate(-180deg) translateX(10px) translateY(-5px);
        
      }

      /*hide the inputs*/
      input{display:none}

      /*show the second levele menu of the selected voice*/
      input:checked ~ ul.submenu{
        max-height:300px;
        transition:max-height 0.2s ease-in;
      }

      /*style for the second level menu*/
      ul.submenu{
        max-height:0;
        padding:0;
        overflow:hidden;
        list-style-type:none;
        border-radius: 10px;
        box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        background-color: #ffffff;
        transition:max-height 0.2s ease-out;
        position:absolute;
        min-width:100%;
        margin:5px 0px 0px 0px;
      }

      ul.submenu li a{
        display:block;
        padding: 9px 12px 9px 12px;
        font-family: 'Open Sans';
        font-size: 12px;
        color: #000000;
        text-decoration:none;
        transition:background .3s;
        white-space:nowrap;
      }

      ul.submenu li a:hover{
        background:#5165ff;
        color: #ffffff;
      }
      select {
        padding: 8px 11px;
        border: none;
        border-radius: 10px;
        box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        background-color: #ffffff;
        min-width: 20vw;
        height: 40px;
        @media screen and(max-width:576px) {
          width: 80vw;
        }
        @media screen and(max-width:1251px) {
          min-width: 20vw;
        }
        @media screen and(min-width:576px) and (max-width: 750px) {
          width: 80vw;
        }
        option {
          margin-top:100px;
          padding: 10px;
          min-width: 20vw;
          height: 30px;
          border:2px solid white;
          border-radius: 100px;
          box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
          font-family: 'Open Sans';
          font-size: 18px;
          &:hover{
            background-color: #5165ff;
            color: #ffffff;
          }
          
          @media screen and(max-width:576px) {
            width: 20vw;
          }
          @media screen and(max-width:1251px) {
            min-width: 20vw;
          }
        }
      }
      .contentItems {
        width: 20vw;
        min-height: 44px;
        /* min-height: 60px; */
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        margin-top: 5px;
        transition: width 1s;
        display: flex;
        font-size: 20px;
        cursor: pointer;
        @media screen and(max-width:576px) {
          width: 80vw;
        }
        @media screen and(min-width:576px) and (max-width: 750px) {
          width: 80vw;
        }

        @media screen and(max-width:1251px) {
          min-width: 20vw;
        }
        &:hover {
          width: 22vw;
          min-height: 44px;
        /* min-height: 60px; */
          font-weight: bold;
          font-size: 18px;
          @media screen and(max-width:576px) {
            width: 90vw;
          }
          @media screen and(max-width:1251px) {
            min-width: 20vw;
          }
        }
        .icon {
          flex: 0.5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          img {
            max-width: 25px;
            max-height: 25px;
          }
        }
        .content {
          flex: 5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          color: #929292;
          font-size: 12px;
          overflow: hidden;
        }
        .value {
          flex: 0.5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          font-size: 12px;
          color: #929292;
        }
      }
    }
  }
  @media (max-width: 750px) {
    .home > :nth-child(1) {
      order: 1 !important;
    }
    .home > :nth-child(2) {
      order: 1 !important;
    }
   .home > :nth-child(3) {
      order: 1 !important;
    }
    .home > :nth-child(4) {
       order: 1 !important;
    }
  }
 
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.ReactModal__Overlay {
 
}
.ReactModal__Content {
  padding: 20px 20px 0px 20px !important;
  @media all and(max-width:576px) {
    border-radius: 0 !important;
    margin-left: 0 !important;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  right: 0px;
  z-index: 13;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.load {height:100vh; display:flex;flex-direction: column;align-items: center;
  justify-content: center;
text-align: center;}

#lightboxBackdrop {
  z-index:9999999;
}

