.about{
    margin:30px 75px;
    @media (max-width:576px) {
        margin:30px 20px;
    }
    @media (min-width:576px)and (max-width:992px) {
        margin:30px 20px;
    }
    .header{
        display:flex;
        justify-content: space-between;
        div{
            span{
            font-family: Helvetica;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            
            }
            hr{
                background-color: #5165ff;
                margin-top:2px;
                height: 4px;
                border: 0px;
            }
        }
        img{
            width:20px;
            height:20px;
            cursor: pointer;
        }
    }
    .content{
        margin-top:35px;
        .heading{
            font-family: "Open Sans"; 
            font-weight: bolder;
            margin:15px 0px;
            font-size: 30px;
            color:#5165FF;
        }
        p{
           
            color:#929292;
            font-size: 16px;
        }
        .subHeading{
            font-size: 18px;
            font-weight: 600;
            margin-left: 60px;
            color: #000000;
        }
        ul{
            font-size:16px;
            color:#929292;
            margin-left:20px;
            font-weight: 'semi-bold';
            li{
                padding:10px 0px;
            }
        }
    }
    .flex-container {
        display: flex;
        @media (max-width:576px) {
            flex-direction: column;
        }
    }

    .flex-container > div {
      background-color: #f1f1f1;
      margin: 10px;
      padding: 10px;
      font-size: 30px;
      img {
        width: 100%;
      }
    }
}