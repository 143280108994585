.signup {
  .arrow-up {
    width: 0;
    height: 0;
    margin-left: 3%;
    margin-top: 15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #fff;
    @media(max-width:576px) {
      margin-left: 10%;
    }
  }

  input {
    width: 60%;
    margin-bottom: 15px;
    padding: 9px;
    border-radius: 5px;
    border: none;
    @media all and(max-width:576px) {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    @media all and(min-width:576px) and(max-width:996px) {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  .button {
    width: 60%;
    margin-top: 10px;
    text-align: center;
    border: none;
    color: #fff;
    background-color: transparent;
    outline: none;
    padding: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    font-weight: "semi-bold";
    font-size: 16px;
    cursor: pointer;
    @media all and(max-width:576px) {
      width: 100%;
    }
    @media all and(min-width:576px) and(max-width:996px) {
      width: 100%;
    }
  }
}
