.getListed{
    padding:30px 75px;
    @media (max-width:576px) {
        padding:30px 20px;
    }
    @media (min-width:576px)and (max-width:992px) {
        padding:30px 20px;
    }
    .button{
          text-decoration: none;
          background-color: #5165FF;
          color:#fff;
          border:none;
          padding:7px 25px;
          border-radius: 5px;
          font-size:13px;
          font-weight: 'semi-bold';
          box-shadow: 0 8px 25px 6px rgba(223, 223, 223, 0.5);
        }
   
    .header{
    margin:20px 50px;
    @media screen and(max-width:576px) {
        margin:20px 0px;
    }
        display:flex;
        justify-content: space-between;
        div{
            span{
            font-family: Helvetica;
            font-size: 18px;
            
            }
            hr{
                background-color: #5165ff;
                margin-top:2px;
                height: 4px;
                border: 0px;
            }
        }
        img{
            width:20px;
            height:20px;
            cursor: pointer;
        }
    }
    .content{
        margin-top:35px;
        .heading{
            font-family: "Open Sans"; 
            font-weight: bolder;
            margin:15px 50px;
            font-size: 30px;
            color:#5165FF;
            @media screen and(max-width:576px) {
                margin: 15px 0px;
            }
        }
        p{
            margin:15px 50px;
            color:#929292;
            font-size: 16px;
            @media screen and(max-width:576px) {
                margin: 15px 0px;
            }
        }
        .formDiv1{
            width:80%;
            margin:15px -20px;
            background-color: #F8F9FF;
            .header{
                margin:15px 65px;
                @media screen and(max-width:576px) {
                    margin:20px 20px;
                }
                display: flex;
                font-size: 16px;
                .number{
                  text-align: center;
                  vertical-align: middle;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                    margin:5px;
                     height: 30px;
                    width: 30px;
                    background-color:#5165FF;
                    color: #fff;
                    border-radius: 50%;

                }
                .subHeading{
                    flex:3;
                    margin:5px 5px 5px 20px;
                    display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
            }
            .content{
                margin:15px 65px;
                min-height:150px;
            }
            
        }
        .formDiv2{
             width:80%;
            margin:15px -20px;
            background-color:#F8F9FF;
            .header{
                margin:15px 65px;
                display: flex;
                font-size: 16px;
                .number{
                  text-align: center;
                  vertical-align: middle;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                    margin:5px;
                     height: 30px;
                    width: 30px;
                    background-color:#5165FF;
                    color: #fff;
                    border-radius: 50%;

                }
                .subHeading{
                    flex:3;
                    margin:5px 5px 5px 20px;
                    display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
            }
            .content{
                margin:15px 65px;
                min-height:150px;
            }

        }
        .formDiv3{
            width:80%;
            margin:15px -20px;
            background-color:#F8F9FF;
            .header{
                margin:15px 65px;
                display: flex;
                font-size: 16px;
                .number{
                  text-align: center;
                  vertical-align: middle;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                    margin:5px;
                     height: 30px;
                    width: 30px;
                    background-color:#5165FF;
                    color: #fff;
                    border-radius: 50%;

                }
                .subHeading{
                    flex:3;
                    margin:5px 5px 5px 20px;
                    display: flex;
                  flex-direction: column;
                  justify-content: center;
                }
            }
            .content{
                margin:15px 65px;
                
            }
            }
    }
}