.login {
  .arrow-up {
    width: 0;
    height: 0;
    margin-left: 10%;
    margin-top: 15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    border-bottom: 10px solid #fff;
    @media all and(min-width:460px) and(max-width:576px) {
      margin-left: 15%;
    }
    @media all and(min-width:577px) and(max-width:768px) {
      margin-left: 20%;
    }
    @media all and(min-width:768px) and(max-width:996px) {
      margin-left: 15%;
    }
    @media all and(min-width:996px) and(max-width:1200px) {
      margin-left: 15%;
    }

    @media(max-width:576px) {
      margin-left: 27%;
    }
  }

  input {
    width: 60%;

    margin-bottom: 15px;
    padding: 9px;
    border-radius: 5px;
    border: none;
    @media all and(max-width:576px) {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    @media all and(min-width:577px) and(max-width:992px) {
      width: 100%;
    }

  }
  .remeber-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    width: 60%;
        @media all and(max-width:576px) {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        @media all and(min-width:577px) and(max-width:992px) {
          width: 100%;
        }
        .button {
          width: 100%;
          margin-top: 10px;
          text-align: center;
          border: none;
          color: #fff;
          border-radius: 6px;
          box-shadow: 0 8px 29px 2px #4253d9;
          background-color: #5165ff;
          outline: none;
          padding: 12px;
          font-weight: "semi-bold";
          font-size: 16px;
          cursor: pointer;
          @media all and(max-width:576px) {
            width: 100%;
          }
          @media all and(min-width:577px) and(max-width:992px) {
            width: 100%;
          }
        }
        .forgot-password {
          margin-top: 10px;
          font-family: "Open Sans";
          font-size: 16px;
          color: #a4afd0;
          cursor: pointer;
        }
}
  .remember-me {
    label {
      font-family: "Open Sans";
      font-size: 16px;
      color: #ffffff;
    }
    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] + label:before {
      content: "\2713";
      cursor: pointer;
      border: 0.1em solid white;
      border-radius: 0.2em;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      padding-left: 0.2em;
      padding-bottom: 0.3em;
      margin-right: 0.2em;
      vertical-align: bottom;
      color: transparent;
      transition: 0.2s;
      background-color: white;
      border-color: white;
    }

    input[type="checkbox"] + label:active:before {
      transform: scale(0);
    }

    input[type="checkbox"]:checked + label:before {
      background-color: white;
      border-color: white;
      color: #5165ff;
      font-size: 20px;
      font-weight: 900;
    }
  }
}
