
.load {position:fixed;
    top:0px;
    bottom:0px;
    right:0px;
    bottom:0px;
    z-index:100; 
    display:flex;flex-direction: 
    column;align-items: center;
  justify-content: center;
text-align: center;

}

